<template lang="pug">
    div
        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 Qu'est-ce que Copilot ?
                p.content(v-if="toggleQuestions[0] === true") 
                    | Copilot permet aux utilisateurs d'avoir une vision globale sur des projets.
                    br
                    | Plusieurs types de module Copilot sont possibles, ayant chacun un nom et des attributs spécifiques correspondant aux besoins du module. Chaque type de module a un onglet dédié dans le menu latéral de la plateforme.

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 Qu'est-ce qu’un projet Copilot ?
                p.content(v-if="toggleQuestions[1] === true") 
                    | Un projet affiche plusieurs attributs détaillant le projet et définis lors de la création. Cela peut être des textes, des dates, des tags, des fichiers…
                    br
                    | Généralement, les attributs sont modifiables par le créateur du projet ou les contributeurs associés.
                    br
                    | Un projet peut avoir 2 états :
                    br
                    ul
                        li · Brouillon : le projet est en cours de rédaction et n'est visible que par son rédacteur.
                        li · En ligne : le projet a été publié et est visible par tous les utilisateurs.
                    br
                    | Un projet en ligne est accessible par tous les utilisateurs de la plateforme via l’onglet du module, du moteur de recherche ou dans des groupes associé au projet.
                    br
                    | Les utilisateurs associés à un projet peuvent le modifier, le dupliquer, le mettre hors ligne et le supprimer.
                    br 
                    | Un onglet news permet aux utilisateurs de communiquer sur le projet. Un historique des modifications est également disponible.

            div.question.block.col-9(:class="{activated: toggleQuestions[2] === true}" @click="toggleQuestions[2] = toggleQuestions[2] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[2] === true}")
                p.text-averta-semibold-20 Comment créer un projet Copilot ?
                p.content(v-if="toggleQuestions[2] === true")
                    | Vous pouvez créer un projet en cliquant sur le bouton "Créer". Lors de la création, veuillez choisir un nom unique pour votre projet et remplir tous les champs requis.
                    br
                    | Le projet peut être directement publié ou mis en brouillon. Il n'est pas possible de publier votre projet tant que tous les champs des "informations basiques" ne sont pas remplis.

        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 什么是Copilot ?
                p.content(v-if="toggleQuestions[0] === true")
                    | Copilot让用户对项目有全局视野。
                    br
                    | Copilot 模块有多种类型，每种都有一个名称和与模块需求相对应的特定属性。每种类型的模块在平台的侧面菜单中都有一个专用选项卡。

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 什么是Copilot项目？
                p.content(v-if="toggleQuestions[1] === true")
                    | 项目会显示多个详细说明项目并在创建过程中设置的属性。它可以是文本、日期、标签、文件……
                    br
                    | 通常，属性可由项目创建者或相关贡献者编辑。
                    br
                    | 一个项目可以有 2 个状态：
                    br
                    ul
                        li · 草稿：项目正在编写中，仅对其编辑者可见。
                        li · 上线：项目已发布，所有用户可见。
                    br
                    | 平台的所有用户都可以通过模块选项卡、搜索引擎或与项目关联的组访问在线项目。
                    br
                    | 与项目关联的用户可以编辑、复制、下线和删除它。
                    br
                    | 动态允许用户就该项目进行交流，还可以查看更改历史记录。

            div.question.block.col-9(:class="{activated: toggleQuestions[2] === true}" @click="toggleQuestions[2] = toggleQuestions[2] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[2] === true}")
                p.text-averta-semibold-20 如何创建Copilot项目？
                p.content(v-if="toggleQuestions[2] === true")
                    | 您可以通过单击“创建”按钮来创建项目。创建时，请为您的项目选择一个唯一的名称并填写所有必填字段。
                    br
                    | 该项目可以直接发表或起草。在填写所有“基本信息”字段之前，无法发布您的项目。

        template(v-if="userLanguage == 'en'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 What is Copilot?
                p.content(v-if="toggleQuestions[0] === true")
                    | Copilot allows users to have a global vision on projects.
                    br
                    | Several types of Copilot module are possible, each having a name and specific attributes corresponding to the needs of the module. Each type of module has a dedicated tab in the side menu of the platform.

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 What is a Copilot project?
                p.content(v-if="toggleQuestions[1] === true")
                    | A project displays several attributes detailing the project and set during creation. It can be texts, dates, tags, files…
                    br
                    | Generally, attributes are editable by the project creator or associated contributors.
                    br
                    | A project can have 2 states:
                    br
                    ul
                        li · Draft: the project is being written and is only visible to its editor.
                        li · Online: The project has been published and is visible to all users.
                    br
                    | An online project is accessible by all users of the platform via the module tab, the search engine or in groups associated with the project.
                    br
                    | Users associated with a project can edit, duplicate, take it offline, and delete it.
                    br
                    | A news tab allows users to communicate about the project. A history of changes is also available.

            div.question.block.col-9(:class="{activated: toggleQuestions[2] === true}" @click="toggleQuestions[2] = toggleQuestions[2] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[2] === true}")
                p.text-averta-semibold-20 How do I create a Copilot project?
                p.content(v-if="toggleQuestions[2] === true")
                    | You can create a project by clicking the "Create" button. When creating, please choose a unique name for your project and complete all required fields.
                    br
                    | The project can be directly published or drafted. It is not possible to publish your project until all the "basic information" fields are filled in.
</template>


<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            toggleQuestions: []
        }
    },

    computed: {
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    }
})
</script>

<style lang="scss" scoped>
@import './question.scss';

</style>